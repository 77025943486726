<template>
  <div class="home-container">
    <div class="home-header">
      <header
        data-thq="thq-navbar"
        class="navbarContainer home-navbar-interactive"
      >
        <span class="logo">RAFAELSTYLING</span>
        <div data-thq="thq-navbar-nav" class="home-desktop-menu">
          <div class="home-buttons"></div>
        </div>
        <div data-thq="thq-burger-menu" class="home-burger-menu">
          <svg viewBox="0 0 1024 1024" class="home-icon socialIcons">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" class="home-mobile-menu1 mobileMenu">
          <div class="home-nav">
            <div class="home-top">
              <span class="logo">RAFAELSTYLING</span>
              <div data-thq="thq-close-menu" class="home-close-menu">
                <svg viewBox="0 0 1024 1024" class="home-icon02 socialIcons">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path>
                </svg>
              </div>
            </div>
            <nav class="home-links">
              <span class="home-nav12 bodySmall">Home</span>
              <span class="home-nav22 bodySmall">Services</span>
              <span class="home-nav32 bodySmall">Gallery</span>
              <span class="home-nav42 bodySmall">Contact</span>
            </nav>
            <div class="home-buttons1">
              <button class="buttonFlat">Login</button>
              <button class="buttonFilled">Register</button>
            </div>
          </div>
          <div>
            <svg
              viewBox="0 0 950.8571428571428 1024"
              class="home-icon04 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="home-icon06 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="home-icon08 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path>
            </svg>
          </div>
        </div>
      </header>
    </div>
    <div class="home-hero">
      <div class="home-hero1 heroContainer">
        <div class="home-container01">
          <h1 class="home-hero-heading heading1">Welkom bij Rafael Styling</h1>
          <span class="home-text">
            Meer dan knippen alleen: Vind jouw unieke haaridentiteit bij
            RafaelStyling.
          </span>
          <span class="home-hero-sub-heading bodyLarge">
            <span>
              <span>
                <span>Your go-to hair salon for the perfect look</span>
                <span><span v-html="rawprbb"></span></span>
              </span>
              <span>
                <span><span v-html="rawfq1e"></span></span>
                <span><span v-html="raw7qgn"></span></span>
              </span>
            </span>
            <span>
              <span>
                <span><span v-html="rawxbk3"></span></span>
                <span><span v-html="rawx4tz"></span></span>
              </span>
              <span>
                <span><span v-html="raw2n4u"></span></span>
                <span><span v-html="rawo6pk"></span></span>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="home-features">
      <div class="featuresContainer">
        <div class="home-features1">
          <div class="home-container02">
            <span class="overline">
              <span>Wat doen wij</span>
              <br />
            </span>
            <h2 class="home-features-heading heading2">
              Ontdek wat wij voor jou kunnen betekenen
            </h2>
            <span class="home-features-sub-heading bodyLarge">
              <span>
                <span>
                  <span>
                    Experience top-notch hair services tailored to your needs
                  </span>
                  <span><span v-html="rawtf5w"></span></span>
                </span>
                <span>
                  <span><span v-html="rawgph9"></span></span>
                  <span><span v-html="rawslog"></span></span>
                </span>
              </span>
              <span>
                <span>
                  <span><span v-html="rawonni"></span></span>
                  <span><span v-html="raw4bik"></span></span>
                </span>
                <span>
                  <span><span v-html="raw2myq"></span></span>
                  <span><span v-html="rawt5at"></span></span>
                </span>
              </span>
            </span>
          </div>
          <div class="home-container03">
            <feature-card
              heading="Verzorgende Haarbehandelingen"
              subHeading="Verwen je haar met onze diepvoedende behandelingen die niet alleen zorgen voor een gezonde glans, maar ook beschermen tegen dagelijkse schade door milieu en styling."
            ></feature-card>
            <feature-card
              heading="Kleurbehandelingen op Maat"
              subHeading="Of je nu gaat voor een subtiele balayage, een complete kleurverandering of iets daar tussenin, onze kleurspecialisten creëren de perfecte tint die bij jou past."
            ></feature-card>
            <feature-card
              heading="Trendy Haarsnitten en Styling"
              subHeading="Van de laatste trends tot tijdloze klassiekers, onze experts knippen en stylen je haar tot perfectie, gebruikmakend van de nieuwste technieken en producten."
            ></feature-card>
            <feature-card
              heading="Precisie Contouring en Bijwerken"
              subHeading="Perfect voor tussentijdse touch-ups van je haar, met subtiele baardtrimming als optionele extra."
            ></feature-card>
          </div>
        </div>
      </div>
    </div>
    <div class="home-pricing">
      <div class="pricingContainer">
        <div class="home-container04">
          <span class="overline">
            <span>Pricing</span>
            <br />
          </span>
          <h2 class="heading2">Our Pricing Plans</h2>
          <span class="home-pricing-sub-heading bodyLarge">
            <span><span>Choose the plan that best suits your needs</span></span>
          </span>
        </div>
        <div class="home-container05">
          <div class="freePricingCard home-pricing-card">
            <div class="home-container06">
              <span class="home-text37 heading3">Free</span>
              <span class="bodySmall">
                Ideal for those looking for a budget-friendly option
              </span>
            </div>
            <div class="home-container07">
              <span class="home-text38">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-free-plan-price">€0</span>
            </div>
            <div class="home-container08">
              <div class="home-container09">
                <span class="home-text41">✔</span>
                <span class="bodySmall">Haircut</span>
              </div>
              <div class="home-container10">
                <span class="home-text42">✔</span>
                <span class="bodySmall">Basic styling</span>
              </div>
              <div class="home-container11">
                <span class="home-text43">✔</span>
                <span class="bodySmall">Consultation</span>
              </div>
            </div>
            <button class="home-button buttonOutline">Continue with Free</button>
          </div>
          <div class="basicPricingCard home-pricing-card1">
            <div class="home-container12">
              <span class="home-text44 heading3">BASIC</span>
              <span class="bodySmall">
                Perfect for regular maintenance and styling
              </span>
            </div>
            <div class="home-container13">
              <span class="home-text45">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-basic-plan-pricing">€30</span>
              <span class="home-text48">/ month</span>
            </div>
            <div class="home-container14">
              <div class="home-container15">
                <span class="home-text49">✔</span>
                <span class="bodySmall">All features of FREE plan</span>
              </div>
              <div class="home-container16">
                <span class="home-text51">✔</span>
                <span class="bodySmall">Haircut &amp; styling</span>
              </div>
              <div class="home-container17">
                <span class="home-text52">✔</span>
                <span class="bodySmall">Shampoo &amp; conditioning</span>
              </div>
              <div class="home-container18">
                <span class="home-text53">✔</span>
                <span class="bodySmall">Blow-dry</span>
              </div>
            </div>
            <button class="home-button1 buttonFilledSecondary">
              Try the Basic plan
            </button>
          </div>
          <div class="proPricingCard home-pricing-card2">
            <div class="home-container19">
              <span class="home-text54 heading3">
                <span>PRO</span>
                <br />
              </span>
              <span class="bodySmall">
                For those seeking a premium experience and advanced styling
              </span>
            </div>
            <div class="home-container20">
              <span class="home-text57">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-pro-plan-pricing">€50</span>
              <span class="home-text60">/ month</span>
            </div>
            <div class="home-container21">
              <div class="home-container22">
                <span class="home-text61">✔</span>
                <span class="bodySmall"> All features of BASIC plan</span>
              </div>
              <div class="home-container23">
                <span class="home-text63">✔</span>
                <span class="bodySmall">Haircut, styling &amp; treatment</span>
              </div>
              <div class="home-container24">
                <span class="home-text64">✔</span>
                <span class="bodySmall">Specialized hair treatments</span>
              </div>
              <div class="home-container25">
                <span class="home-text65">✔</span>
                <span class="bodySmall">Advanced styling techniques</span>
              </div>
            </div>
            <button class="home-button2 buttonFilledSecondary">
              Try the PRO plan
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="home-banner">
      <div class="bannerContainer home-banner1">
        <h1 class="home-banner-heading heading2">
          Discover Our Services at Rafael Styling
        </h1>
        <span class="home-banner-sub-heading bodySmall">
          <span>
            <span>
              <span>
                At Rafael Styling, we offer a range of hair services tailored to
                your needs. From cuts to colors, we have you covered. Our
                experienced stylists are dedicated to helping you achieve your
                desired look.
              </span>
              <span><span v-html="rawv1pl"></span></span>
            </span>
            <span>
              <span><span v-html="raw9xiv"></span></span>
              <span><span v-html="raw7y4v"></span></span>
            </span>
          </span>
          <span>
            <span>
              <span><span v-html="rawt8x6"></span></span>
              <span><span v-html="rawug2b"></span></span>
            </span>
            <span>
              <span><span v-html="rawayho"></span></span>
              <span><span v-html="rawvkbc"></span></span>
            </span>
          </span>
        </span>
        <button class="buttonFilled">Learn More</button>
      </div>
    </div>
    <div class="home-faq">
      <div class="faqContainer">
        <div class="home-faq1">
          <div class="home-container26">
            <span class="overline">
              <span>FAQ</span>
              <br />
            </span>
            <h2 class="home-text83 heading2">Veel gestelde vragen</h2>
            <span class="home-text84">
              Hier vindt u de antwoorden op enkele van de meest gestelde vragen
              door onze klanten.
            </span>
          </div>
          <div class="home-container27">
            <app-question1
              answer="U kunt een afspraak maken door ons te bellen, onze website te bezoeken of langs te komen tijdens openingstijden."
              question="Hoe kan ik een afspraak maken?"
            ></app-question1>
            <app-question1
              answer="Ja, we hebben regelmatig speciale aanbiedingen en promoties. Houd onze sociale media pagina's in de gaten of abonneer u op onze nieuwsbrief om op de hoogte te blijven."
              question="Heeft u speciale aanbiedingen of promoties?"
            ></app-question1>
            <app-question1
              answer="Wij zijn geopend van dinsdag tot en met zaterdag, van 8:00 tot 18:00 uur. Gesloten op maandagen."
              question="Wat zijn uw openingstijden?"
            ></app-question1>
            <app-question1
              answer="Zeker, we bieden gespecialiseerde bruidskapsel diensten aan. Neem contact met ons op om een consultatie te plannen en uw droomlook voor de bruiloft te bespreken."
              question="Biedt u bruidskapsel diensten aan?"
            ></app-question1>
          </div>
        </div>
      </div>
    </div>
    <div class="home-footer">
      <footer class="footerContainer home-footer1">
        <div class="home-container28">
          <span class="logo">RAFAELSTYLING</span>
        </div>
        <div class="home-separator"></div>
        <div class="home-container29">
          <span class="bodySmall home-text85">© 2024 Rafik Styling</span>
          <div class="home-icon-group1">
            <svg
              viewBox="0 0 877.7142857142857 1024"
              class="home-icon10 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="home-icon12 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path>
            </svg>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import FeatureCard from '../components/feature-card'
import AppQuestion1 from '../components/question1'

export default {
  name: 'Home',
  props: {},
  components: {
    FeatureCard,
    AppQuestion1,
  },
  data() {
    return {
      rawprbb: ' ',
      rawfq1e: ' ',
      raw7qgn: ' ',
      rawxbk3: ' ',
      rawx4tz: ' ',
      raw2n4u: ' ',
      rawo6pk: ' ',
      rawtf5w: ' ',
      rawgph9: ' ',
      rawslog: ' ',
      rawonni: ' ',
      raw4bik: ' ',
      raw2myq: ' ',
      rawt5at: ' ',
      rawv1pl: ' ',
      raw9xiv: ' ',
      raw7y4v: ' ',
      rawt8x6: ' ',
      rawug2b: ' ',
      rawayho: ' ',
      rawvkbc: ' ',
    }
  },
  metaInfo: {
    title: 'Agreeable Great Kookabura',
    meta: [
      {
        property: 'og:title',
        content: 'Agreeable Great Kookabura',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-buttons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-links {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-nav12 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav22 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav42 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.home-hero1 {
  height: 259px;
  align-items: center;
  justify-content: center;
}
.home-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  text-align: center;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  max-width: 800px;
  text-align: center;
}
.home-text {
  font-size: 18px;
}
.home-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-container02 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-features-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-container03 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
.home-pricing {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-pricing-sub-heading {
  text-align: center;
}
.home-container05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text37 {
  text-transform: uppercase;
}
.home-container07 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text38 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-free-plan-price {
  font-size: 4rem;
  font-weight: 700;
}
.home-container08 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-container09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text41 {
  line-height: 150%;
}
.home-container10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text42 {
  line-height: 150%;
}
.home-container11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text43 {
  line-height: 150%;
}
.home-button {
  width: 100%;
}
.home-container12 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text44 {
  text-transform: uppercase;
}
.home-container13 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text45 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-basic-plan-pricing {
  font-size: 4rem;
  font-weight: 700;
}
.home-text48 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-container14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-container15 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text49 {
  line-height: 150%;
}
.home-container16 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text51 {
  line-height: 150%;
}
.home-container17 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text52 {
  line-height: 150%;
}
.home-container18 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text53 {
  line-height: 150%;
}
.home-button1 {
  width: 100%;
}
.home-container19 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text54 {
  text-transform: uppercase;
}
.home-container20 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text57 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-pro-plan-pricing {
  font-size: 4rem;
  font-weight: 700;
}
.home-text60 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-container21 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-container22 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text61 {
  line-height: 150%;
}
.home-container23 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text63 {
  line-height: 150%;
}
.home-container24 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text64 {
  line-height: 150%;
}
.home-container25 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text65 {
  line-height: 150%;
}
.home-button2 {
  width: 100%;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner-heading {
  text-align: center;
}
.home-banner-sub-heading {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-container26 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text83 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text84 {
  text-align: left;
}
.home-container27 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container28 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container29 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.home-icon12 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }
  .home-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-container05 {
    align-items: center;
    flex-direction: column;
  }
  .home-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
  }
  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
  }
  .home-banner-sub-heading {
    max-width: 100%;
  }
  .home-text84 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-nav12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav42 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container03 {
    grid-template-columns: 1fr;
  }
  .home-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container05 {
    align-items: center;
    flex-direction: column;
  }
  .home-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-faq1 {
    flex-direction: column;
  }
  .home-container26 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-text84 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container29 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text85 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu1 {
    padding: 16px;
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    margin-bottom: 0px;
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container29 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text85 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
